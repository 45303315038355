const Inter = [
  {
    space_src: "noad",
    space_type: "inters",
    space_id: "aaaaa",
    space_js: "ssssssssssssssssssss"
  }
];

const Native1 = [
  {
    space_src: "noad",
    space_type: "native",
    space_id: "aaaaa",
    space_js: "ssssssssssssssssssss"
  }
];

const Native2 = [
  {
    space_src: "noad",
    space_type: "native",
    space_id: "aaaaa",
    space_js: "ssssssssssssssssssss"
  }
];

const Native3 = [
  {
    space_src: "noad",
    space_type: "native",
    space_id: "aaaaa",
    space_js: "ssssssssssssssssssss"
  }
];

const Banner = [
  {
    space_src: "noad",
    space_type: "native",
    space_id: "aaaaa",
    space_js: "ssssssssssssssssssss"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
