import type { RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/index";

export enum pathNames {
  guide = "Guide",
  guideResult = "IconList",
  home = "Home",
  homeWallpaper = "HomeWallpaper",
  ranking = "Ranking",
  headList = "HeadList",
  wallpaperList = "WallpaperList",
  headDetail = "HeadDetail",
  wallpaperDetail = "WallpaperDetail"
}

const getRedirect = function () {
  const query = getUrlParams();

  // 如果存在 地址中存在pathName 并且存在与路由表中 则将pathName设置为baseRoutePath
  if (query.pathName && Object.values(pathNames).includes(query.pathName)) return { name: query.pathName };

  if (!process.env.VUE_APP_START) return "/home";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else return "/home";
};

export default <RouteRecordRaw[]>[
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/guide",
    name: pathNames.guide,
    // component: () => import("@/views/guide/GuideView.vue"),
    component: () => import("@/views/guide/GuideNewView.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: true,
      spaceKeys: ["Guide_banner", "Guide_native"]
    }
  },
  {
    path: "/guideResult",
    name: pathNames.guideResult,
    component: () => import(/* webpackPrefetch: true  */ "@/views/guide/GuideResultView.vue"),
    meta: {
      title: APP_NAME,
      imageType: "head",
      spaceKeys: ["Result_native", "Result_banner", "Result_inter", "Result_dialog"]
    }
  },
  {
    path: "/home",
    redirect: "/home/head",
    component: () => import(/* webpackPrefetch: true  */ "@/views/home/HomeView.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: true
    },
    children: [
      {
        path: "/home/head",
        name: pathNames.home,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/sub-views/TabHeadView.vue"),
        meta: {
          title: "情侣头像小游戏",
          spaceKeys: ["Home_inter", "Home_dialog", "Home_native", "Home_banner"]
        }
      },
      {
        path: "/home/wallpaper",
        name: pathNames.homeWallpaper,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/sub-views/TabWallpaperView.vue"),
        meta: {
          title: "高清壁纸",
          spaceKeys: ["Home_inter", "Home_dialog", "Home_native", "Home_banner"]
        }
      }
    ]
  },
  {
    path: "/ranking",
    name: pathNames.ranking,
    component: () => import(/* webpackPrefetch: true  */ "@/views/ranking/RankingView.vue"),
    meta: {
      title: "排行榜",
      spaceKeys: ["List_native", "List_banner"]
    }
  },
  {
    path: "/headList",
    name: pathNames.headList,
    component: () => import(/* webpackPrefetch: true  */ "@/views/image/ImageListView.vue"),
    meta: {
      title: "头像列表",
      imageType: "head",
      spaceKeys: ["List_native", "List_banner"]
    }
  },
  {
    path: "/wallpaperList",
    name: pathNames.wallpaperList,
    component: () => import(/* webpackPrefetch: true  */ "@/views/image/ImageListView.vue"),
    meta: {
      title: "壁纸列表",
      imageType: "wallpaper",
      spaceKeys: ["List_native", "List_banner"]
    }
  },
  {
    path: "/headDetail",
    name: pathNames.headDetail,
    component: () => import(/* webpackPrefetch: true  */ "@/views/image/ImageDetailView.vue"),
    props: (route) => ({ object_id: route.query.object_id }),
    meta: {
      title: "头像详情",
      imageType: "head",
      spaceKeys: ["Detail_native", "Detail_banner", "Detail_inter", "Detail_dialog"]
    }
  },
  {
    path: "/wallpaperDetail",
    name: pathNames.wallpaperDetail,
    component: () => import(/* webpackPrefetch: true  */ "@/views/image/ImageDetailView.vue"),
    props: (route) => ({ object_id: route.query.object_id }),
    meta: {
      title: "壁纸详情",
      imageType: "wallpaper",
      spaceKeys: ["Detail_native", "Detail_banner", "Detail_inter", "Detail_dialog"]
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      title: "404"
    }
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
];
